@import "styles/definitions";

.fixedContainer {
  position: fixed;
  z-index: 6;
  left: 0;
  right: 0;

  @include breakpoint(xssm) {
    display: none;
  }
}
