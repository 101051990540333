@import "styles/definitions";

$modal-width: 90%;
$modal-max-width: 920px;
$modal-height: 90vh;
$modal-max-height: 675px;

.modalStyles {
  width: $modal-width;
  max-width: $modal-max-width;
  height: $modal-height;
  max-height: $modal-max-height;
  overflow: hidden;
  background-color: $white;

  @media (max-width: 468px) {
    width: 100%;
    max-width: unset;
    height: 100%;
    max-height: unset;
    overflow: hidden auto;
    -webkit-overflow-scrolling: touch; /* Safari touch scrolling */
    margin: 0;
  }

  &.small {
    max-height: $modal-max-height - 100px;

    @media (max-width: 468px) {
      max-height: unset;
    }
  }
}

.dynamicSizingModal {
  height: auto;
  width: auto;
}

.container {
  &.small {
    :global(.pom-closeButton--rounded) {
      @media (min-width: 469px) {
        top: calc((100% - min(#{$modal-height}, #{$modal-max-height - 100px})) / 2 + 12px);
      }
    }
  }

  :global(.pom-closeButton--rounded) {
    width: 32px;
    height: 32px;
    position: fixed;

    /* To show close button above the too wide scroll bar, need fixed position.
       Positioning of fixed button in the correct spot is a bit of calc */
    top: calc((100% - min(#{$modal-height}, #{$modal-max-height})) / 2 + 12px);
    right: calc((100% - min(#{$modal-width}, #{$modal-max-width})) / 2 + 12px);
    background-color: $gray-200;

    &:hover {
      background-color: $gray-300;
    }

    @media (max-width: 468px) {
      background-color: $white;
      position: absolute;
      top: 12px;
      right: 12px;

      &:focus,
      &:active {
        outline: none;
      }

      &:hover {
        background-color: $gray-200;
      }
    }
  }
}

.dynamicSizingContainer {
  :global(.pom-closeButton--rounded) {
    position: absolute;
    top: 12px;
    right: 12px;
  }
}
