@import "styles/definitions";

.container {
  margin: 0 auto;
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: $gray-200;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.textPlaceholder {
    background-color: transparent;
    overflow: visible;
  }
}

.spinnerCentered {
  left: calc(50% - 8px);
  margin: auto;
  color: $gray-500;

  &.textPlaceholder {
    left: 0;
  }
}
