@import "styles/definitions";

.loginLink {
  font-size: $xxsmall;
  font-weight: $regular;
  font-family: $cera;
  margin-right: 10px;

  a {
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    color: $gray-600;

    &:hover {
      color: $blue-600;
    }
  }
}

@media screen and (max-width: 961px) {
  .loginLink {
    text-align: center;

    &.hide {
      display: none;
    }
  }
}
