$primary: #0f346c;
$secondary: #fff;
$white: #fff;
$black: #000;
$gray-700: #303235;
$gray-600: #696d75;
$gray-500: #b2b5bd;
$gray-400: #d3d6dc;
$gray-300: #e4e6eb;
$gray-200: #f8f9fa;
$gray-100: #fafbfb;
$blue-twitter: #1da1f2;
$blue-facebook: #3b5998;
$blue-700: #0d2a54;
$blue-600: #0f346c;
$blue-500: #245091;
$blue-400: #2e62af;
$blue-300: #6b96d6;
$blue-200: #a1bce6;
$blue-100: #c8dcfd;
$blue-90: #f4f8fe;

// new color palette
$ba-blue: #002684;
$ba-blue-line: #002c9b;
$red-400: #ba272e;
$red-300: #d64148;
$red-200: #e64c53;
$red-100: #fa5768;
$yellow-400: #e09900;
$yellow-300: #f0ac00;
$yellow-200: #f1b828;
$yellow-100: #ffcb47;
$orange-400: #d34600;
$orange-300: #e65710;
$orange-200: #f26c29;
$orange-100: #ff8142;
$green-400: #008761;
$green-300: #00996e;
$green-200: #00a979;
$green-100: #1eb389;
$cyan: #00a0df;
