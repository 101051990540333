/* stylelint-disable at-rule-no-unknown */

/* ================================================== *\
    Media queries breakpoints
\* ================================================== */

// Small screen / tablet
$screen-sm-min: 768px !default;

// Medium screen / desktop
$screen-md-min: 962px !default;

// Large screen / wide desktop
$screen-lg-min: 1200px !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1) !default;
$screen-sm-max: ($screen-md-min - 1) !default;
$screen-md-max: ($screen-lg-min - 1) !default;

// Breakpoints
@mixin breakpoint($point) {
  @if $point == xs {
    @media (max-width: $screen-xs-max) { @content; }
  } @else if $point == sm {
    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) { @content; }
  } @else if $point == md {
    @media (min-width: $screen-md-min) and (max-width: $screen-md-max) { @content; }
  } @else if $point == lg {
    @media (min-width: $screen-lg-min) { @content; }
  } @else if $point == xssm { // for both small breakpoints
    @media (max-width: $screen-sm-max) { @content; }
  } @else {
    @warn "Breakpoint mixin supports: xs, sm, md, lg, xssm";
  }
}
