@import "styles/definitions";

.newCouponContainer {
  font-size: $xsmall;
  background-color: #002684;
  height: 50px;

  p {
    margin: 0;
    font-size: $small;
    color: $white;
  }

  .couponBox {
    box-sizing: border-box;
    padding: 6px 28px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .message {
    &:hover {
      cursor: pointer;
      text-decoration: underline;
      text-decoration-thickness: 1px;
      text-underline-offset: 3px;
      text-decoration-color: $white;
    }
  }

  .circleCheck {
    display: flex;
  }

  .appliedText {
    letter-spacing: 0.56px;
    font-weight: $semibold;
  }

  .promotionText {
    vertical-align: middle;
    height: auto;
    font-family: $cera;
    text-align: center;
    font-weight: $regular;
  }

  .btnOffer {
    align-self: center;
    margin: 0 0 0 16px;
    min-width: 100px;
    height: 32px;
  }

  .couponIcon {
    margin-top: 6px;
    align-self: center;
  }

  @include breakpoint(xs) {
    .promotionText {
      line-height: initial;
      font-size: 14px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      text-align: left;
    }

    .btnOffer {
      span {
        visibility: hidden;

        &::after {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          visibility: visible;
          content: "Claim";
        }
      }
    }
  }
}
