@import "styles/definitions";

.testimonialSection img {
  width: 100%;
  min-width: 1200px;
  height: 100%;
}

.testimonialSection {
  min-height: 416px;
  max-height: 536px;
  margin: 0 0 64px;
  overflow: hidden;
  position: relative;
}

.testimonialSection p {
  margin: 0;
}

.testimonialSection p.title {
  padding-top: 60px;
  font-family: $cera;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: #00a0df;
}

.testimonialSection p.testimonialText {
  font-family: $chronicledeck;
  font-size: 24px;
  font-weight: 900;
  text-align: center;
  color: #002c9b;
  margin: 28px auto 0;
  max-width: 509px;
  min-width: 312px;
  font-style: normal;
  line-height: normal;
  height: auto;
  padding: 0 52px;
}

.testimonialSection p.userName {
  font-family: $cera;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 2.4px;
  text-align: center;
  color: #002c9b;
  padding-bottom: 39px;
  text-transform: uppercase;
}

.testimonialSection div.rectangle {
  width: 100px;
  height: 4px;
  background-color: #00a0df;
  margin: 32px auto 29px;
}

.testimonialSection div.fixed {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: #fff;
  max-width: 614px;
  transform: translate(-50%, -50%);
  overflow: hidden;
}

.testimonialSection div.fixed img {
  height: 20px;
  width: auto;
  position: absolute;
  min-width: 0;
}

@media only screen and (max-width: 959px) {
  .testimonialSection p.testimonialText {
    font-size: 18px;
    padding: 0 42px;
  }

  .testimonialSection p.userName {
    font-size: 14px;
  }

  .testimonialSection p.title {
    padding-top: 52px;
    font-size: 16px;
  }

  .testimonialSection {
    margin: 40px 0;
  }
}

@media only screen and (max-width: 767px) {
  .testimonialSection p.title {
    padding: 44px 20px 0;
  }

  .testimonialSection p.testimonialText {
    margin: 16px auto 0;
    padding: 0 20px;
    line-height: 1.5;
  }

  .testimonialSection p.userName {
    padding-bottom: 24px;
  }

  .testimonialSection div.rectangle {
    margin: 24px auto;
  }

  .testimonialSection {
    margin: 16px 0;
  }
}
