@import "styles/definitions";

@media screen and (min-width: $screen-sm-min) {
  .headerNavItem {
    padding: 11px 16px;

    a {
      font-weight: $regular;
      font-family: $cera;
      color: $gray-600;
      font-size: $xxsmall;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      height: 26px;
      line-height: 26px;
      padding-top: 2px;
      text-decoration: none;

      &:hover {
        color: $blue-600;
        text-decoration: none;
      }
    }
  }

  .isActiveNavItem {
    a {
      color: $blue-600;
    }
  }
}

@include breakpoint(xssm) {
  .headerNavItem {
    padding: 12px 24px;
    margin-left: 6px;
    box-shadow: 0 1px 0 rgb(0 0 0 / 10%);

    a {
      font-weight: $regular;
      font-family: $cera;
      color: $blue-500;
      font-size: $medium;
      height: 26px;
      line-height: 26px;
      padding-top: 2px;
      text-decoration: none;

      &:hover {
        color: $blue-700;
        text-decoration: none;
      }
    }
  }

  .isActiveNavItem {
    a {
      color: $black;
    }
  }
}
