@import "styles/definitions";

.container {
  width: 100%;

  .header {
    display: flex;
    align-items: center;
  }

  .headerButtonContainer {
    justify-content: center;
    align-items: center;
    margin: 16px 15px;
    padding: 0;
  }

  .loginLink {
    border: 1px solid $gray-500;
    background: transparent;
    height: 40px;
    font-size: $medium;
    font-weight: $med;

    a {
      color: $blue-500;
      width: 100%;
      text-align: center;
    }
  }
}
