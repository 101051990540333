$tera: 58px;
$giga: 36px;
$mega: 30px;
$kilo: 26px;
$xxlarge: 24px;
$xlarge: 20px;
$large: 18px;
$medium: 16px;
$small: 15px;
$xsmall: 14px;
$xxsmall: 13px;
$milli: 12px;
$light: 300;
$regular: 400;
$med: 500;
$semibold: 600;
$bold: 700;
$helvetica: "HelveticaNeue", "Helvetica Neue", "HelveticaNeueRoman", "HelveticaNeue-Roman", "Helvetica Neue Roman", "TeXGyreHerosRegular", "Helvetica", "Tahoma", "Geneva", "Arial", sans-serif; /* stylelint-disable-line max-line-length */
$cera: "Cera Pro","HelveticaNeue", "Helvetica Neue", "HelveticaNeueRoman", "HelveticaNeue-Roman", "Helvetica Neue Roman", "TeXGyreHerosRegular", "Helvetica", "Tahoma", "Geneva", "Arial", sans-serif; /* stylelint-disable-line max-line-length */
$chronicle: "Chronicle Ssm", "Times", serif;
$chronicledeck: "Chronicle Deck", "Times", serif;
