@import "styles/definitions";

.promoButton {
  display: inline-block;
  margin-left: 18px;
  width: 140px;
  padding: 8px 20px 9px;
  font-size: 12px;
  line-height: 15px;
  background-color: #f26226;
  border-color: #f26226;
  text-transform: uppercase;
  border-radius: 2px;
  letter-spacing: 0.1em;
  color: #fff;
  cursor: pointer;
}

.pricingCouponNotice {
  display: flex;
  align-items: center;
}

@include breakpoint(xs) {
  .promoButton {
    font-size: 12px;
    padding: 3px 14px 2px;
    margin-left: 18px;
  }
}

.couponBanner {
  background-color: $gray-200;
}

.container {
  position: sticky;
  top: 0;
  z-index: 101;
}
