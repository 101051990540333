$desktopModalHeight: 675px;
$mobileModalHeight: 500px;

.container {
  box-sizing: border-box;
  width: 100%;
  max-height: $desktopModalHeight;
  padding: 56px 40px 40px;

  @media screen and (max-width: 468px) {
    max-height: $mobileModalHeight;
    padding: 56px 16px 40px;
  }
}

.contentWrapper {
  max-width: 556px;
  width: 100%;
}
