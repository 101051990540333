@import "styles/definitions";

.planSelectorContainer {
  flex-grow: 0;
  margin: 0 auto 8px;
  border-radius: 8px;
  border: solid 1px $gray-400;
  background-color: $white;
  box-sizing: border-box;
  width: 360px;
  padding: 24px 16px;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    padding: 24px;
    width: 734px;
  }

  @media screen and (max-width: 392px) {
    width: calc(100% - 32px);
  }
}

.planSelectorWrapper {
  flex-direction: column;
  display: flex;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
  }
}

.planSelectorTopRow {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;

  @media screen and (min-width: 768px) {
    width: 325px;
    height: 208px;
  }
}

.planSelectorMiddleRow {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  @media screen and (min-width: 768px) {
    width: 321px;
  }
}

.planTypeSelector {
  height: 40px;
  border-radius: 6px;
  border: none;
  width: 100%;
  outline: solid 1px $gray-400;
  outline-offset: -1px;
  background: $white;
  box-shadow: none;
  margin: 0 0 16px;
  overflow: visible;
  position: relative;
  display: inline-block;
  text-align: center;
  padding: 0;
  list-style: none;
  white-space: nowrap;

  li {
    display: inline-block;
    width: 50%;
  }

  li button {
    white-space: nowrap;
    width: 100%;
    color: #002c9b;
    font-family: $cera;
    font-size: $medium;
    position: relative;
    z-index: 1;
    padding: 10px;
    transition: color;
    transition-delay: 0.1s;
    box-sizing: border-box;
    border: none;
    outline: none;
    background: transparent;
    cursor: pointer;
  }

  .selectedPlanType button {
    color: white;
  }

  &::after {
    border-radius: 6px;
    background-color: #002684;
    height: 40px;
    top: 75%;
    transform: translateY(-75%);
    content: "";
    position: absolute;
    color: white;
    width: 50%;
    transition:
      left 0.2s cubic-bezier(0.93, 0.76, 0.49, 1.13),
      background-color 0.2s;
  }

  &[data-selected="0"]::after {
    left: 0;
  }

  &[data-selected="1"]::after {
    left: 50%;
  }
}

.pnrServingInformation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px;
  align-items: center;

  .pnrServingTitle {
    color: #303236;
    font-family: $cera;
    font-size: $medium;
    font-style: normal;
    font-weight: $med;
    line-height: 18px;
  }

  .quantitySize {
    color: #6a6d75;
    font-family: $cera;
    font-size: $small;
    font-weight: $regular;
    line-height: 40px;
    font-style: normal;
  }
}

.planListTitle {
  text-align: left;

  h1 {
    margin-bottom: 16px;
    font-family: $chronicledeck;
    font-size: $mega;
    color: #002684;
    font-weight: $semibold;
  }
}

.skipSubtitleContainer {
  text-align: center;
}

.subtitle {
  font-family: $cera;
  font-size: $xsmall;
  line-height: 1.2;
  color: var(--gray-600);
}

.planSelectorBottomRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.quantityTitle {
  color: #303236;
  font-family: $cera;
  font-size: $medium;
  font-style: normal;
  font-weight: $med;
  line-height: 18px;
  margin-bottom: 8px;
}

.quantitySelector {
  width: 100%;
}

.continueButton {
  width: 100%;

  @media screen and (min-width: 768px) {
    width: 200px;
  }
}

.deliveriesBeginMessage {
  color: $cyan;
  text-align: left;
  font-family: $cera;
  font-size: $milli;
  font-style: italic;
  font-weight: $med;
  margin: 8px 0 0;
  line-height: 12px;
}
