@import "styles/definitions";

.ratingsSection {
  background-color: $gray-200;
  text-align: center;
  padding: 16px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}

.ratingsTitle {
  font-family: $cera;
  font-size: $xxlarge;
  font-weight: bold;
  line-height: 1.33;
  color: $gray-700;
  padding-right: 52px;
}

.appStoreText {
  font-family: $cera;
  font-size: $xxlarge;
  color: #00a0df;
  text-transform: uppercase;
}

.ratingText {
  font-family: $cera;
  font-weight: bold;
  font-size: $xxlarge;
  color: $gray-700;
  margin: 0 0 10px 5px;
  vertical-align: text-top;
}

.ratingsSubText {
  font-family: $cera;
  font-weight: normal;
  font-size: $xsmall;
  text-align: center;
  color: $gray-700;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@media only screen and (max-width: 767px) {
  .ratingsTitle {
    font-size: $large;
    padding-right: 16px;
    max-width: 180px;
  }

  .appStoreText {
    font-size: $large;
  }

  .ratingContainer {
    min-width: 164px;
  }

  .ratingsSubText {
    font-size: $xxsmall;
  }

  .hideOnMobile {
    display: none;
  }
}

.appStoreSubtext {
  margin: 8px 0 0;
}

.ratingsSection .get-cooking-btn {
  margin-top: 24px;
  line-height: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: rgb(0 38 132);
  letter-spacing: 2.5px;
  font-size: 16px;
  padding: 0 40px;
}

.get-cooking-btn {
  line-height: 50px;
}

.ratingsImg {
  vertical-align: bottom;
}

.getCookingBtn {
  margin-right: auto;
  font-size: $medium;
  padding: 0 24px;
  height: 50px;
  line-height: 50px;
  margin-top: 24px;
  border-radius: 25px;
  background-color: rgb(0 38 132);
  letter-spacing: 2.5px;
  outline: none;
  cursor: pointer;
  position: relative;
  text-transform: uppercase;
  font-weight: $bold;
  text-align: center;
  color: #fff;
  display: inline-block;
  text-decoration: none;
  border: 0;
  font-family: $cera;
}
