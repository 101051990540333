@import "styles/definitions";

.pnRBannerContainer {
  max-width: 1120px;
  margin: 40px auto;
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: 1200px) {
    margin-left: min(40px);
    margin-right: min(40px);
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    margin: 40px 16px;
  }
}

.copyContainer {
  padding: 24px 16px;
  background-color: $ba-blue;
  font-family: $cera;
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    font-size: $medium;
    font-weight: $regular;
    line-height: 22px;
    color: $blue-100;
    margin: 0;
  }

  .pricing {
    font-weight: $bold;
    color: $white;
  }

  .subText {
    font-size: $xsmall;
    font-style: italic;
    color: $cyan;
    margin-top: 8px;
  }

  .new {
    font-size: $xxsmall;
    font-weight: $semibold;
    letter-spacing: 2px;
    text-transform: uppercase;
    text-decoration: none;
    color: #002c9b;
    margin: 16px 0 0;
    align-self: start;
    height: 40px;
    padding: 0 19px;
  }

  @media only screen and (min-width: 768px) {
    padding: 24px 32px;
    width: 370px;
    height: 275px;
    box-sizing: border-box;
  }
}

.linkUnderline {
  margin-top: 2px;
  height: 3px;
  max-width: 101px;
  background-color: $white;
}

.badge {
  position: absolute;
  transform: translate(-22px, -28px);
}

.copyHeading {
  color: $white;
  font-size: $xlarge;
  font-weight: $bold;
  margin-bottom: 8px;

  span {
    color: $cyan;
  }
}

.pnRBannerImg {
  overflow: hidden;
  position: relative;
  flex-grow: 1;
  max-height: none;
  border-top: 1px solid $ba-blue;
  border-right: 1px solid $ba-blue;
  border-bottom: 1px solid $ba-blue;

  img {
    max-width: 758px;
    vertical-align: middle;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: unset;
    min-width: 100%;
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }
}

.pnRBannerMobileImg {
  overflow: hidden;
  max-height: 215px;
  border-top: 1px solid $ba-blue;
  border-right: 1px solid $ba-blue;
  border-left: 1px solid $ba-blue;

  img {
    width: 100%;
  }

  @media only screen and (min-width: 768px) {
    display: none;
  }
}
