@import "styles/definitions";

.homepageCouponBannerContainer {
  background: $ba-blue;
  position: relative;
  padding: 8px 0;
  margin: 0;
  color: $white;
}

.promoMessage {
  font-family: $cera;
  font-weight: $regular;
  font-size: $small;
  line-height: 24px;
  margin-left: 0;
  margin-right: 16px;
  text-align: center;

  @include breakpoint(xs) {
    font-size: $xsmall;
    line-height: 16px;
    margin: 0 0 8px;
  }
}

.terms {
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
}

.promoContent {
  display: flex;
  justify-content: center;
  padding: 0 16px;
  align-items: center;

  @include breakpoint(xs) {
    flex-direction: column;
  }
}

.redeemButton {
  min-width: 97px;
  padding: 0 16px;
  box-sizing: content-box;

  > span {
    display: block;
    text-wrap: nowrap;
    text-align: center;
    width: 100%;
  }
}
