// WARNING: DO NOT import CSS styles into this file - it should be left as variables and mixins only!
// This file is imported many times throughout the project and any styles added
// will be duplicated via imports and quickly bloat our CSS bundle.

@import "styles/pomelo/index";
@import "styles/_colors";
@import "styles/pomegranate-ui/_colors";
@import "styles/pomegranate-ui/_mixins";
@import "styles/pomegranate-ui/_typography";

/* ================================================== *\
    Header Heights
\* ================================================== */

$promoBannerHeaderHeight: 50px;
$navBarHeight: 68px;
$mobileNavBarHeight: 51px;
