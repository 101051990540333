@media screen and (min-width: 961px) {
  .container {
    display: block;
    margin: 0 auto;
    margin-left: 16px;
  }

  .linksList {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
