@import "styles/definitions";

.container {
  position: absolute;
  float: right;
  right: 20px;
  top: 25px;

  @include breakpoint(xs) {
    top: 17px;
  }
}

.mobileHeaderButton {
  display: inline;
  position: relative;
  cursor: pointer;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
}
