@import "styles/definitions";

.firefoxOverflowFix {
  overflow: clip;
}

.container {
  @include breakpoint(xs) {
    :global(.pom-FlashMessage) {
      margin-top: 20px;
    }
  }
}

.planSelectorWrapper {
  margin-bottom: 60px;

  @include breakpoint(xs) {
    margin-bottom: 40px;
  }
}
