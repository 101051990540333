@import "styles/definitions";

.homePageSection {
  margin: 64px 64px 80px;
  position: relative;
  text-align: center;
}

.baPhoto {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 25px 28px;

  @media only screen and (max-width: 480px) {
    margin: 0 0 28px;
  }
}

.baPhoto img {
  height: 100%;
  width: 100%;
}

.baPhotoTitle {
  font-family: $cera;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  color: #303236;
  margin-bottom: 12px;
}

.baPhotoSubtitle {
  font-family: $cera;
  font-size: 15px;
  line-height: 1.33;
  color: #303236;
  font-weight: normal;
  text-align: center;
  margin: 0;
}

.whyBaSection img {
  max-width: 100%;
  margin: 0 0 16px;

  img {
    margin: 0 0 16px;
  }

  @media only screen and (max-width: 1100px) {
    margin: 0 14px 16px;
  }

  @media only screen and (max-width: 480px) {
    margin: 0 0 16px;
  }
}

.whyBaSection {
  display: flex;
  justify-content: space-around;
  margin: 40px 0 36px;
}

.homePageSection .sectionTitle {
  width: 495px;
  font-size: 40px;
  font-weight: bold;
  color: #002c9b;
  font-family: $chronicledeck;
}

.homePageSection .sectionDescription {
  margin: auto;
  margin-top: 10px;
  max-width: 343px;
  font-family: $cera;
  font-size: 20px;
  line-height: 1.2;
  text-align: center;
  color: #303236;
}

.homePageSection .getStarted {
  margin-bottom: 0;
  font-family: $cera;
  font-size: 16px;
  color: #303236;
}

.homePageSection .getStarted .textStyle1 {
  font-weight: bold;
}

.homePageSection .getCookingBtn {
  margin-top: 24px;
  line-height: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: rgb(0 38 132);
  letter-spacing: 2.5px;
  font-size: 16px;
  padding: 0 40px;
}

.getCookingBtn {
  font-size: 16px;
  padding: 0 40px;
  height: 50px;
  margin-top: 35px;
  line-height: 50px;
  border-radius: 25px;
  background-color: rgb(0 38 132);
  letter-spacing: 2.5px;
  outline: none;
  cursor: pointer;
  position: relative;
  text-transform: uppercase;
  font-weight: 500;
  text-align: center;
  color: #fff;
  display: inline-block;
  text-decoration: none;
  border: 0;
  font-family: $cera;

  &:hover {
    text-decoration: none;
  }
}

@media only screen and (max-width: 1199px) {
  .homePageSection {
    margin: 52px 24px 64px;
  }
}

@media only screen and (max-width: 969px) {
  .homePageSection {
    margin: 40px 16px 64px;
  }
}

@media only screen and (max-width: 959px) {
  .homePageSection .sectionTitle {
    font-size: 32px;
  }

  .homePageSection .sectionDescription {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .whyBaSection {
    flex-direction: column;
  }

  .homePageSection {
    margin: 64px 0 80px;
  }
}

@media only screen and (max-width: 480px) {
  .homePageSection {
    margin: 40px 0 64px;
  }

  .homePageSection .sectionTitle {
    font-size: 24px;
  }

  .homePageSection .sectionDescription {
    font-size: 16px;
  }
}
