@import "styles/definitions";

.overlayContainer {
  display: block;
  cursor: pointer;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: $black;
  opacity: 0.3;
  width: 100%;
  height: 100%;
  position: fixed;
}

.mobileNav {
  padding-bottom: 20px;
  position: fixed;
  height: 100%;
  width: 328px;
  right: -328px;
  top: 0;
  z-index: 500;
  background-color: $white;
  transform: translateX(-328px);
  transition: transform 0.5s ease-out;
  white-space: nowrap;
  font-family: $cera;
  overflow-y: auto;

  nav > ul li:first-child {
    a {
      color: $gray-700;
      font-weight: $med;
      letter-spacing: 0.1em;
    }
  }
}
