@import "styles/definitions";

.couponDetails {
  display: flex;
  flex-direction: column;
  align-items: center;

  .dripContainer {
    display: flex;
    justify-content: space-evenly;
    gap: 16px;

    @media screen and (max-width: 468px) {
      flex-wrap: wrap;
    }
  }

  .couponContainer {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    font-size: $xxsmall;

    .couponBox {
      width: 30px;
      margin-bottom: 8px;
    }
  }

  .couponAmount {
    font-family: $cera;
    font-size: $xxsmall;
    font-weight: $med;
    text-align: center;
    color: $gray-600;
    line-height: 16px;
  }

  .promoText {
    margin-top: 24px;
    margin-bottom: 0;
    font-family: $cera;
    font-size: $xsmall;
    color: $gray-700;
    font-weight: $bold;
    line-height: 19px;
  }

  .couponLegalCopy {
    margin-top: 16px;
    margin-bottom: 0;
    font-size: $milli;
    font-weight: $regular;
    color: $gray-600;
    line-height: 16px;
  }
}
