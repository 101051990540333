@import "styles/definitions";

.ctaSplash {
  margin-bottom: 40px;
  position: relative;
}

.ctaSplash .topContainer {
  margin: 48px auto 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transform: none;
  width: auto;
  text-align: center;
  display: block;
  position: absolute;
  height: 205px;
}

.ctaSplash .topContainerTxt h2 {
  color: #002684;
  font-size: 32px;
  font-family: $chronicledeck;
  font-weight: $semibold;
  max-width: 440px;
  margin: 0 16px;

  @media screen and (max-width: 768px) {
    margin-left: auto;
    margin-right: auto;
  }
}

.ctaSplash .homepageHero {
  display: block;
  position: relative;
  height: 100%;
}

.ctaSplash .topContainerTxt {
  position: relative;
}

@media screen and (min-width: 768px) {
  .ctaSplash .topContainerTxt h2 {
    margin: 0;
    max-width: 300px;
    font-size: 28px;
  }

  .ctaSplash .topContainer {
    margin: 40px auto 40px 24px;
    text-align: left;
    width: 750px;
  }

  .ctaSplash .topContainerTxt {
    position: absolute;
  }

  .ctaSplash {
    margin-bottom: 50px;
  }

  .cta-splash .top-container-txt .get-cooking-btn {
    display: inline-block;
    margin-right: auto;
  }
}

@media only screen and (min-width: 960px) {
  .ctaSplash .topContainerTxt h2 {
    font-size: 32px;
    max-width: 350px;
    text-align: left;
  }

  .ctaSplash .topContainer {
    margin: 75px auto 75px 40px;
    text-align: left;
    width: 940px;
  }
}

@media only screen and (min-width: 1200px) {
  .ctaSplash .topContainerTxt h2 {
    font-size: 40px;
    max-width: 440px;
  }

  .ctaSplash .homepageHero {
    width: 100%;
  }

  .ctaSplash .topContainer {
    width: 1170px;
  }
}

.ctaSplash .backgroundImageOverlay {
  background-image: none;
  height: 375px;
  overflow: hidden;
  position: relative;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  .homepageHero {
    display: block;
    position: relative;
  }

  .homepageHeroMobile {
    display: none;
  }

  @include breakpoint(xs) {
    height: 457px;
    background-image: none;

    .homepageHero {
      display: none;
    }

    .homepageHeroMobile {
      display: block;
      position: relative;
      height: 100%;
    }
  }
}

.ctaSplash .topContainerTxt .getCookingBtn {
  margin-right: auto;
  font-size: 16px;
  padding: 0 24px;
  height: 50px;
  margin-top: 32px;
  line-height: 50px;
  border-radius: 25px;
  background-color: rgb(0 38 132);
  letter-spacing: 2.5px;
  outline: none;
  cursor: pointer;
  position: relative;
  text-transform: uppercase;
  font-weight: $bold;
  text-align: center;
  color: #fff;
  display: inline-block;
  text-decoration: none;
  border: 0;
  font-family: $cera;

  @media only screen and (max-width: 960px) {
    margin-top: 16px;
  }
}
