@import "styles/definitions";

.weeklyOptionsSection .sectionMainTitle {
  color: #002684;
  text-transform: uppercase;
  font-size: 36px;
  letter-spacing: 5.4px;
  text-align: center;
}

.weeklyOptionsSection .link {
  cursor: pointer;
}

.weeklyOptionsSection .sectionSubTitle {
  font-family: $chronicledeck;
  font-size: 48px;
  color: #00a0df;
  text-align: center;
}

.weeklyOptionsSection {
  a.browseMenusBtn {
    display: block;
    width: 260px;
    height: 50px;
    border-radius: 24.8px;
    border: solid 2px #002c9b;
    background-color: #fff;
    margin: 38px auto 46px;
    letter-spacing: 2.5px;
    box-sizing: border-box;
    padding: 0;
    line-height: 46px;
    font-size: 16px;
    color: #002c9b;
    outline: none;
    cursor: pointer;
    position: relative;
    text-transform: uppercase;
    font-weight: 500;
    text-align: center;
    font-family: $cera;
    text-decoration: none;
  }
}

.weeklyPhotoSection {
  display: grid;
  grid-template-columns: repeat(5, minmax(168px, 1fr));
  column-gap: 31px;
  row-gap: 48px;
  justify-content: center;
  margin: 40px 41px 38px;
}

.weeklyPhotoSection img {
  width: 100%;
  height: 100%;
}

.weeklyPhotoSection p {
  margin: 0;
}

.weeklyPhotoSection .title {
  font-family: $cera;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 1.3px;
  color: #002c9b;
  text-transform: uppercase;
  text-align: center;
  margin-top: 16px;
}

.weeklyPhotoSection .subTitle {
  width: 100%;
  font-family: $cera;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.33;
  color: #6a6d75;
  text-align: center;
  margin-top: 8px;
}

@media only screen and (max-width: 1100px) {
  .weeklyPhotoSection {
    column-gap: 16px;
    row-gap: 40px;
  }
}

@media only screen and (max-width: 959px) {
  .weeklyPhotoSection {
    grid-template-columns: repeat(10, 198px);
    overflow: scroll;
    justify-content: left;
    margin: 40px 0;
    padding: 0 16px;
  }

  .weeklyOptionsSection .sectionMainTitle {
    font-size: 24px;
  }

  .weeklyOptionsSection .sectionSubTitle {
    font-size: 32px;
  }
}

@media only screen and (max-width: 480px) {
  .weeklyPhotoSection {
    margin: 24px 0;
  }
}
