@import "styles/definitions";

.gettingStartedSection {
  height: 340px;
  overflow: hidden;
  position: relative;
}

.gettingStartedSection {
  a.splashGetStartedBtn {
    display: block;
    width: 234px;
    height: 50px;
    font-size: 16px;
    line-height: 50px;
    border-radius: 25px;
    background-color: #002684;
    margin: 24px auto;
    letter-spacing: 2.5px;
    padding: 0 24px;
    box-sizing: border-box;
    text-decoration: none;
    font-family: $cera;
    font-weight: 500;
    text-align: center;
    color: #fff;
    outline: none;
    cursor: pointer;
    position: relative;
    text-transform: uppercase;
  }
}

.gettingStartedSection img {
  height: 100%;
  min-width: 900px;
  transform: translate(-50%, 0);
  left: 50%;
  position: relative;
}

.gettingStartedSection p.title {
  font-family: $chronicledeck;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  color: #002c9b;
}

.gettingStartedSection p.subTitle {
  font-family: $cera;
  font-size: 18px;
  line-height: 1.33;
  text-align: center;
  color: #6a6d75;
}

.gettingStartedSection p.subTitle span {
  font-weight: bold;
  color: #00a0df;
}

.gettingStartedSection .fixed {
  padding: 40px 0 38px;
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: #fff;
  width: 550px;
  height: 218px;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
}

a.getStartedBtnMobile {
  background-color: #002684;
  margin: 0;
  height: 50px;
  width: 100%;
  box-sizing: border-box;
  line-height: 50px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 2px;
  border-radius: 50px;
  padding: 0 40px;
}

.getStartedBtnMobileContainer {
  position: fixed;
  bottom: 0;
  background: #fff;
  width: 100%;
  z-index: 4;
  visibility: hidden;
  padding: 8px 16px;
  box-sizing: border-box;
}

@media only screen and (max-width: 768px) {
  .gettingStartedSection {
    height: 280px;
  }

  .gettingStartedSection div.fixed {
    width: 400px;
    height: 180px;
    padding: 24px 0 28px;
  }

  .gettingStartedSection p.title {
    font-size: 24px;
  }

  .gettingStartedSection p.subTitle {
    font-size: 16px;
  }

  .gettingStartedSection a#splash-get-started-btn {
    margin: 16px auto;
  }
}

@media only screen and (max-width: 767px) {
  .gettingStartedSection div.fixed {
    width: 312px;
  }
}
