@import "styles/definitions";

.planPricingContainer {
  display: flex;
  flex-direction: column;
}

.pricesRow {
  display: flex;
  flex-direction: row;

  div {
    font-size: $medium;
    text-align: left;
    margin: 0 0 4px;
    color: $gray-700;
  }
}

.pricingRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.priceAmountBox {
  display: flex;
  flex-direction: column;

  div {
    font-size: $xsmall;
    color: $gray-600;

    &.priceAmount {
      font-family: $cera;
      font-size: $large;
      font-weight: $bold;
      line-height: 0.8;
      color: #303236;
      margin-right: 4px;

      &.discounted {
        color: #00a0df;
      }
    }

    &.discountedPrice {
      font-size: $medium;
      text-decoration: line-through;
      line-height: 1;
      color: #b1b5bd;
      font-family: $cera;
    }

    &.discountCopy {
      color: $green-100;
      padding-right: 1rem;
    }
  }

  .pricingLabel {
    font-family: $cera;
    font-size: $small;
    line-height: 1.14;
    margin-right: 23px;
  }
}
