@import "styles/definitions";

@media screen and (min-width: $screen-sm-min) {
  .accountDropdownButton {
    padding: 0 20px;
    position: relative;

    span {
      font-weight: $regular;
      font-family: $cera;
      color: $gray-600;
      font-size: $xxsmall;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      padding-top: 8px;

      &:hover {
        color: $blue-600;
      }
    }

    &:hover {
      cursor: pointer;

      .accountDropdownIcon svg {
        color: $blue-600;
        transform: rotate(180deg) !important;
        transition-duration: 0.25s;
      }
    }

    .accountDropdownMenu {
      position: absolute;
      padding-top: 5px;
      width: 168px;
      z-index: 10;
      box-shadow: 0 1px 8px rgb(0 0 0 / 30%);
      border-radius: 2px;
      background-color: $white;
      right: 16px;
      margin: 0;

      a {
        font-family: $cera;
        font-size: $xxsmall;
        color: $blue-500;
        padding: 8px 15px;
        display: block;

        &:hover,
        &:active,
        &:focus {
          background-color: $blue-600;
          color: $white;
          text-decoration: none;
        }
      }

      li {
        line-height: 14px;
        text-align: left;
      }

      .navLogout {
        border-top: 1px solid $gray-400;
        margin-top: 5px;
        line-height: 18px;
      }
    }
  }
}

@include breakpoint(xs) {
  .accountDropdownMenu {
    a {
      cursor: pointer;
      font-weight: $regular;
      font-family: $cera;
      color: $blue-500;
      font-size: $medium;
      height: 26px;
      line-height: 26px;
      padding-top: 2px;
      text-decoration: none;

      &:hover,
      &:active,
      &:focus {
        color: $blue-700;
        text-decoration: none;
      }
    }

    li {
      padding: 12px 24px;
      margin-left: 6px;
      box-shadow: 0 1px 0 rgb(0 0 0 / 10%);
    }
  }
}
