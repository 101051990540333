@import "styles/definitions";

.sticky {
  position: sticky;
  top: 0;
}

.fixed {
  position: fixed;
}

.header {
  display: flex;
  box-sizing: border-box;
  border-bottom: solid $gray-400 1px;
  background-color: $white;
  max-height: 68px;
  font-size: 10px;
  padding: 10px 0;
  left: 0;
  right: 0;
  z-index: 7;

  .container {
    margin: 0 auto;
    display: flex;
    width: 100%;
    padding: 0 16px;

    .logo {
      svg {
        position: relative;
        height: 44px;
        width: 105px;
        top: 2px;
      }
    }

    @include breakpoint(xs) {
      margin: 0;
      max-height: 30px;

      .logo {
        svg {
          position: relative;
          height: 30px;
          width: unset;
        }
      }
    }
  }
}

.headerPlaceholder {
  height: 68px;

  @include breakpoint(xs) {
    height: 50px;
  }
}

.couponPresent {
  top: $promoBannerHeaderHeight;
}
